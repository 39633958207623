<template>
  <div>
   
  </div>
</template>

<script>


export default {
  data() {
    return {
      tableData: [],
      list: {
        carNumber: "", //车辆编号
        beginDate: "",
        endDate: "",
        date: "",
      },
      listTwo: {
        date: "",
      },
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      emptyImg: require("../../assets/images/empty.png"),
      vehicleNumber: "SV10008",
      vehicleType: "微公交",
      vehicleSubType: "SharingVan1.0",
      vehicleOrganization: "东风技术中心园区",
    };
  },
  mounted() {
    this.getTableDate();
  },
  methods: {
    //获取分页数据
    async getTableDate(pagination) {
      this.loading = true;
      if (pagination) {
        if (pagination.page) {
          this.page.currentPage = pagination.page;
          this.page.pageSize = pagination.limit;
        }
      }
      window.sessionStorage.setItem("Vepage", this.page.currentPage);
      window.sessionStorage.setItem("Vesize", this.page.pageSize);
      if (this.list.date === null) {
        this.list.date = [];
        this.list.beginDate = "";
        this.list.endDate = "";
      }

      if (this.list.date.length === 2) {
        this.list.beginDate = this.list.date[0];
        this.list.endDate = this.list.date[1];
      }
      _VeDataList(this.list).then((res) => {
        if (res.success == true) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less">
.acquisitionHeadF {
  display: flex;
}

.acquisitionHead {
  color: #666;
  font-size: 14px;
  background-color: #fff;
  display: flex;
  height: 36px;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 12px;
}

.acquisitionHead > div {
  margin-right: 40px;
}

.acquisitionHead span {
  color: #333333;
  font-size: 14px;
  font-weight: 600;
}

.carCan {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.uploadRecord {
  width: 45%;
  padding: 20px 14px;
  background-color: #fff;
  box-sizing: border-box;
  color: #666666;
  font-size: 16px;

  thead {
    background: #f5f8fa !important;
  }

  .has-gutter > tr > th {
    background: #f5f8fa;
  }

  .el-table__empty-block {
    min-height: 300px;
    margin-top: 20px;
  }
}

.historyHead {
  height: 48px;
  background: #f5f8fa;
  padding-left: 20px;
  line-height: 48px;
  color: #909399;
  font-size: 16px;
  font-weight: 550;
}

.historicalFile {
  .el-empty {
    padding: 20px 0px;

    img {
      width: 90px;
      height: 100px;
    }

    p {
      margin-top: 20px;
    }
  }
}

.packetDownload {
  width: 53%;
  padding: 20px 30px;
  background-color: #fff;
  box-sizing: border-box;
  color: #666666;
  font-size: 16px;
}
</style>